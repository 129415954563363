import React, { Suspense, useEffect, useState } from 'react';
import { ReactQueryConfigProvider } from 'react-query';
import { ThemeProvider } from 'styled-components/macro';
import { theme } from 'theme/MainTheme';
import { toast } from 'react-toastify';
import GlobalStyle from 'theme/GlobalStyle';
import 'react-toastify/dist/ReactToastify.css';
// import UserPanel from 'views/UserPanel';
import Notification from 'components/Notification/Notification';
import { ReactComponent as InfoIcon } from 'assets/Notifications/info.svg';
import { ReactComponent as ErrorIcon } from 'assets/Notifications/error.svg';
import { ReactComponent as SuccessIcon } from 'assets/Notifications/success.svg';
import { ReactComponent as WarningIcon } from 'assets/Notifications/warning.svg';
import SharedSesionAuthHOC from 'helpers/SharedSesionAuthHOC';
import jwt from 'jsonwebtoken';
// import * as Sentry from '@sentry/browser';
import { componentLoader } from 'helpers/componentLoader';
import Service from 'components/Service/Service';
import Loader from 'components/Loader/Loader';
import { getBuildDate } from 'helpers/utils';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  const trident = ua.indexOf('Trident/');
  return msie > 0 || trident > 0;
};

if (isIE()) {
  window.location = `microsoft-edge:${window.location}`;
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS
  // integrations: [
  //   new Integrations.BrowserTracing({
  //     tracingOrigins: ["localhost", /^\//],
  //   }),
  // ],
  // tracesSampleRate: 0.0
});

// Sentry.init({ dsn: "https://55a7224b6d734aa9a7bb228653e419a4@o413771.ingest.sentry.io/5301789" });

toast.configure();
const queryConfig = {
  suspense: true,
  refetchOnMount: true,
  refetchOnWindowFocus: false,
  refetchAllOnWindowFocus: false,
  refetchInterval: false
  // position: toast.POSITION.BOTTOM_RIGHT,
  // manual: true,
  // retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
  // staleTime: 0,
  // cacheTime: 5 * 60 * 1000,
  // refetchInterval: false,
  // refetchOnMount: true,
  // isDataEqual: (previous, next) => true,
};

function App() {
  const [currentUser, setCurrentUser] = useState({
    isLoggedIn: false,
    userEmail: '',
    userId: '',
    tokenExpires: '',
    tokenCreated: ''
  });
  const [awaitForLogin, setAwaitForLogin] = useState(false);

  const UserPanel = React.lazy(() => componentLoader(() => import('views/UserPanelQr')), 5);

  const notifications = (status, msgText) => {
    if (status !== null) {
      switch (status) {
        case true:
          toast.success(<Notification msgText={msgText} icon={<SuccessIcon />} />);
          break;
        case 'info':
          toast.info(<Notification msgText={msgText} icon={<InfoIcon />} />, { autoClose: 10000 });
          break;
        case 'warning':
          toast.warn(<Notification msgText={msgText} icon={<WarningIcon />} />);
          break;
        default:
          toast.error(<Notification msgText={msgText} icon={<ErrorIcon />} />);
          break;
      }
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get('t') !== null) {
      window.sessionStorage.setItem('token', urlParams.get('t'));
    }
  }, []);

  useEffect(() => {
    const token = window.sessionStorage.getItem('token');
    console.log(token);
    if (token) {
      const decodedUserInfo = {
        email: jwt.decode(token)?.email,
        id: jwt.decode(token)?.id,
        tokenCreated: Date.now(),
        tokenExpires: jwt.decode(token)?.exp
      };
      setCurrentUser({
        isLoggedIn: true,
        userEmail: decodedUserInfo.email,
        userId: decodedUserInfo.id,
        tokenCreated: Date.now(),
        tokenExpires: decodedUserInfo.tokenExpires
      });
      setAwaitForLogin(true);
    }
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={<p>Ups! Coś poszło nie tak.</p>}>
      <ReactQueryConfigProvider config={queryConfig}>
        {console.log('build date: ', getBuildDate())}
        <GlobalStyle />

        <ThemeProvider theme={theme}>
          <Suspense fallback={<Loader />}>
            <UserPanel
              notifications={notifications}
              currentUser={currentUser}
              setCurrentUser={setCurrentUser}
              awaitForLogin={awaitForLogin}
              setAwaitForLogin={setAwaitForLogin}
            />
          </Suspense>
        </ThemeProvider>
      </ReactQueryConfigProvider>
    </Sentry.ErrorBoundary>
  );
}

export default SharedSesionAuthHOC(App);
// export default App;
